import {mapGetters, mapActions, mapMutations} from 'vuex'

import ContactMixin from 'js/mixins/contact-modal-mixin'

import template from './index.pug'

export default Vue.extend({
  template: template(),

  mixins: [ContactMixin],

  computed: {
    ...mapGetters({
      municipality: 'currentMunicipality',
      countryAlpha2: 'countryAlpha2',
      showLanguageModal: 'showLanguageModal',
      availableMunicipalities: 'availableMunicipalities',
      canSelectMunicipality: 'canSelectMunicipality'
    }),

    notifications: {
      get() {
        return this.$store.state.notifications
      },

      set(value) {
        this.$store.commit('UPDATE_NOTIFICATIONS', value)
      }
    },

    mainClass() {
      if (_.includes(this.$route.name, 'municipality')) return 'ns-layout__content__map'
      return ''
    },

    layoutClass() {
      if (_.includes(this.$route.name, 'municipality')) return 'municipality-layout'
      return ''
    }
  },

  watch: {
    async '$route.params.municipality'() {
      await this.loadInformation()
    },

    async '$route.query.openContactModal'(newValue) {
      this.showContactModal = newValue === 'true'
    }
  },

  async created() {
    await this.loadInformation()
  },

  // TODO: mover esta lógica a otro sitio mejor
  methods: {
    ...mapActions(['loadAdministrativeAreaFromMunicipality', 'closeLanguageModal']),

    ...mapMutations(['setMunicipality']),

    async loadInformation() {
      this.getMunicipality()
      await this.getAdministrativeArea()
    },

    getMunicipality() {
      if (_.includes(this.$route.name, 'municipality')) {
        const countryAlpha2 = (this.$route.params.country || '').toLowerCase()
        const municipalityName = (this.$route.params.municipality || '').toLowerCase()
        let municipality

        if (countryAlpha2 === this.countryAlpha2.toLowerCase()) {
          municipality = this.availableMunicipalities.find(
            mun => mun.country_alpha2 === countryAlpha2 && mun.name_normalized === municipalityName
          )
        }

        if (municipality) {
          this.setMunicipality(municipality)
        } else {
          this.$router.push({name: 'error', params: {errorCode: 404}})
        }
      } else if (this.canSelectMunicipality) {
        this.setMunicipality(undefined)
      }
    },

    async getAdministrativeArea() {
      const {municipality, loadAdministrativeAreaFromMunicipality} = this

      if (municipality) {
        await loadAdministrativeAreaFromMunicipality({municipalityId: municipality.id})
      }
    },

    reloadPage() {
      location.reload()
    }
  }
})
