import Vue from 'vue'
import {mapGetters, mapActions} from 'vuex'

import CityImage from 'assets/images/cities/icons/city.svg'

import SessionMenu from 'js/components/layout-menu/_session-menu.js'
import AddressInfo from 'js/components/address-info/index.js'
import ComplaintsBook from 'js/components/complaints-book/index.js'
import FollowUs from 'js/components/follow-us/index.js'
import ContactMixin from 'js/mixins/contact-modal-mixin'

import HelpLinks from './_help-links.js'

import template from './index.pug'

// @vue/component
export default Vue.extend({
  template: template(),

  components: {
    SessionMenu, AddressInfo, ComplaintsBook, FollowUs, HelpLinks
  },

  mixins: [ContactMixin],

  props: {
    controlMenu: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters(['currentMunicipality', 'canSelectMunicipality']),

    municipalityIcon() {
      if (this.currentMunicipality) {
        return this.currentMunicipality.image_url || CityImage
      }

      return undefined
    }
  },

  methods: {
    ...mapActions(['openLanguageModal']),

    closeMenu() {
      this.$emit('close-menu')
    },

    logout() {
      this.closeMenu()
      this.$emit('logout')
    },

    login() {
      this.closeMenu()
      this.$emit('login')
    },

    register() {
      this.closeMenu()
      this.$emit('register')
    },

    goToHome() {
      this.closeMenu()
      this.$router.push({name: 'home'})
    },

    goToPrivacyPolicy() {
      this.closeMenu()
      this.$router.push({name: 'privacy-policy'})
    },

    goToTransportRequest() {
      this.closeMenu()
      this.$router.push({name: 'transport-request'})
    },

    goToClaims() {
      this.closeMenu()
      this.$router.push({name: 'claims'})
    },

    openLanguageSelector() {
      this.closeMenu()
      this.openLanguageModal()
    },

    goToContactModal() {
      this.closeMenu()
      this.openContactModal()
    },

    goToInstitutionalInformation() {
      this.closeMenu()
      this.$router.push({name: 'institutional-information'})
    },

    goToPrivacyPolicyText() {
      this.closeMenu()
      this.$router.push({name: 'privacy-policy-text'})
    },

    goToCookiesPolicy() {
      this.closeMenu()
      this.$router.push({name: 'cookies-policy-text'})
    },

    goToAdvertising() {
      this.closeMenu()
      this.$router.push({name: 'advertising'})
    },

    goToRenting() {
      this.closeMenu()
      this.$router.push({name: 'renting'})
    },

    goToBolsa() {
      try {
        const language = navigator.language
        const url = 'https://eva-bus.com/upload/frm_inscricao.php'+
          '?t=Motorista%20de%20pesados%20de%20passageiros'
        if (language == null) {
          window.open(url)
        } else if (language.includes('es')) {
          window.open(url)
        } else if (language.includes('pt')) {
          window.open(url)
        } else {
          window.open(url)
        }
      } catch (err) {
        window.open(url)
      }
    },

    goToDenuncia() {
      try {
        const language = navigator.language
        const url = 'https://transportesdoalgarve.integrityline.com/'
        if (language == null) {
          window.open(url)
        } else if (language.includes('es')) {
          window.open(url)
        } else if (language.includes('pt')) {
          window.open(url)
        } else {
          window.open(url)
        }
      } catch (err) {
        window.open(url)
      }
    },

    goToGeneralConditionsOfTransport() {
      this.closeMenu()
      this.$router.push({name: 'general-conditions-of-transport'})
    },

    goToPressNews() {
      this.closeMenu()
      this.$router.push({name: 'press-news'})
    },
  }
})
