import Vue from 'vue'
import template from './index.pug'

// @vue/component
Vue.component('general-conditions-text', {
  template: template(),
  computed: {
    chapters() {
      return this.getChapters('generalConditionsOfTransport')
    },
    te() {
      return this.$te.bind(this)
    }
  },
  methods: {
    getChapters(rootChapterName) {
      const chapters = []

      const introduction = this.getIntroductionChapter(rootChapterName)
      if (introduction) { chapters.push(introduction) }

      for (let index = 1; ; index++) {
        const chapter = this.getChapter(rootChapterName, index)

        if (!chapter) { break }

        chapters.push(chapter)
      }

      return chapters
    },

    getChapter(rootChapterName, index) {
      const {te} = this

      const chapterName = `${rootChapterName}.chapter-${index}`

      if (!te(chapterName)) {
        return null
      }

      const levelOneListName = `${chapterName}.levelOne`
      const hasLevelOneList = te(levelOneListName)

      const levelTwoListName = `${chapterName}.levelTwo`
      const hasLevelTwoList = te(levelTwoListName)

      const levelThreeListName = `${chapterName}.levelThree`
      const hasLevelThreeList = te(levelThreeListName)

      const levelFourListName = `${chapterName}.levelFour`
      const hasLevelFourList = te(levelFourListName)

      const chapter = {
        name: chapterName,
        title: te(`${chapterName}.title`),
        subtitle: te(`${chapterName}.subtitle`),
        levelOneList: hasLevelOneList,
        levelOneListChapters: hasLevelOneList ? this.getChapters(levelOneListName) : [],
        levelTwoList: hasLevelTwoList,
        levelTwoListChapters: hasLevelTwoList ?  this.getChapters(levelTwoListName) : [],
        levelThreeList: hasLevelThreeList,
        levelThreeListChapters: hasLevelThreeList ? this.getChapters(levelThreeListName) : [],
        levelFourList: hasLevelFourList,
        levelFourListChapters: hasLevelFourList ? this.getChapters(levelFourListName) : [],
        paragraphs: this.getParagrahps(chapterName)
      }

      return chapter
    },

    getParagrahps(chapterName) {
      const {te} = this

      const paragraphs = []

      for (let pIndex = 1; ; pIndex++) {
        const paragraphKey = `${chapterName}.paragraph-${pIndex}`

        if (!te(paragraphKey)) { break }

        paragraphs.push(paragraphKey)
      }

      return paragraphs
    },

    getIntroductionChapter(rootChapterName){
      const {te} = this

      if (!te(`${rootChapterName}.introduction`)) {
        return null
      }

      return {
        name: `${rootChapterName}.introduction`,
        titleIntroduction: te(`${rootChapterName}.title`),
        paragraphs: this.getParagrahps(`${rootChapterName}.introduction`)
      }
    }
  }
})
