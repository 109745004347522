import AppConfigService from 'js/services/api/app-config'

export default ({
  state: {
    currentAppConfig: null
  },
  mutations: {
    setAppConfig(state, appConfig) {
      state.currentAppConfig = appConfig
    }
  },
  getters: {
    hasAppConfig(state) { return state.appConfig !== null },
    currentAppConfig(state) { return state.currentAppConfig || {} }
  },
  actions: {
    async loadAppConfig({commit}) {
      const appConfig = await AppConfigService.current()
      commit('setAppConfig', appConfig)
    }
  }
})
