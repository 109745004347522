import Vue from 'vue'
import { mapGetters } from 'vuex'

import CitySelect from './city-select'
import distribusion from '../../../components/distribusion/index.js'
import template from './index.pug'

export default Vue.extend({
  template: template(),

  components: {
    CitySelect,
    distribusion
  },

  mounted() {
    window.onpopstate = function () {
      location.reload()
    }
  },

  computed: {
    ...mapGetters(['availableMunicipalities']),
  },

  methods: {
    municipalityClass(index) {
      if (index === 0) {
        return 'end-sm'
      }
      return ''
    }
  }

})
