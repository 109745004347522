import Vue from 'vue'
import {TELEPHONE} from 'js/lib/info.js'

import template from './index.pug'

// @vue/component
export default Vue.extend({
  template: template(),

  data() {
    return {
      telephone_href: TELEPHONE.telephone_href,
      telephone_number: TELEPHONE.number
    }
  }
})
