/*
 * Parseamos los errors para devolver algo más usable
 */
function parseErrors(request, response) {
  // Parseamos los errors para devolver algo más usable
  const errors = {}

  Object.keys(response.data.errors).forEach(key => {
    let error = response.data.errors[key]

    // cuando devuelve traducciones / errors: {session: ["Invalid"]}
    if (_.isArray(error)) error = error[0]

    // cuando devuelve tokens / errors: {session: [{error: "invalid_session"}]}
    if (_.isObject(error) && error.error) error = error.error

    // si la petición tiene el parámetro localeRootKey buscamos la traducción del token
    if (request.localeRootKey &&
      Vue.i18n.te(`${request.localeRootKey}.errors.${key}.${error}`)
    ) {
      errors[key] = Vue.i18n.t(`${request.localeRootKey}.errors.${key}.${error}`)
    } else {
      errors[key] = error
    }
  })

  response.data.errors = errors
}

function interceptor(request, next) {
  if (!request.skipLoading) Vue.store.commit('incrementLoading')

  // Añadimos el token a todas las peticiones que hagamos con vue-resource
  if (Vue.auth && Vue.auth.token()) {
    request.headers.set('Authorization', `Bearer ${Vue.auth.token()}`)
  }

  next(response => {
    if (!request.skipLoading) Vue.store.commit('decreaseLoading')

    if (response.status === 401) {
      Vue.auth.setToken('')
      Vue.auth.setUser(undefined)
      Vue.router.push({name: 'home'})
    } else if (response.status === 404) {
      Vue.router.push({name: 'error', params: {errorCode: response.status}})
    } else if (response.status === 422 && response.data.errors) {
      parseErrors(request, response)
    }
  })
}

export default interceptor
