import Vue from 'vue'
import template from './index.pug'

// @vue/component
Vue.component('favorite-button', {
  template: template(),

  props: {
    favorite: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    favoriteClass() {
      return this.favorite ? 'is-favourite' : undefined
    }
  },

  methods: {
    setFavorite() {
      this.$emit('set-favorite')
    }
  }
})
