import template from './index.pug'

Vue.component('cookies', {
  template: template(),

  data() {
    return {
      acceptedCookies: undefined
    }
  },

  created() {
    this.acceptedCookies = this.$cookies.get('acceptedCookies')
  },

  methods: {
    acceptCookies() {
      this.$cookies.set('acceptedCookies', 'true', Infinity)
      this.acceptedCookies = 'true'
    },

    goToPrivacyPolicy() {
      this.$router.push({name: 'privacy-policy'})
      this.$el.querySelector('.ns-button--secondary').blur()
    }
  }
})
