import Vue from 'vue'
import {SOCIAL_MEDIA, DEFAULT} from 'js/lib/info.js'

import {mapGetters} from 'vuex'

import AppLogo from 'js/components/layout-menu/_app-logo.js'
import PhoneNumber from 'js/components/phone-number/index.js'
import SessionMenu from 'js/components/layout-menu/_session-menu.js'
import MunicipalitySelector from 'js/components/layout-menu/header/_municipality-selector.js'
import ContactMixin from 'js/mixins/contact-modal-mixin'

import template from './_full-header.pug'

// @vue/component
export default Vue.extend({
  template: template(),

  components: {
    AppLogo,
    SessionMenu,
    PhoneNumber,
    MunicipalitySelector
  },

  mixins: [ContactMixin],

  data() {
    return {
      default: DEFAULT,
      socialMediaItems: SOCIAL_MEDIA
    }
  },

  computed: {
    ...mapGetters(['currentAppConfig', 'currentMunicipality']),

    appStoreLinks() {
      const appStoreLinks = []

      if (this.currentAppConfig.google_play_url) {
        appStoreLinks.push({
          icon: 'android',
          link: this.currentAppConfig.google_play_url
        })
      }

      if (this.currentAppConfig.apple_store_url) {
        appStoreLinks.push({
          icon: 'ios',
          link: this.currentAppConfig.apple_store_url
        })
      }

      return appStoreLinks
    },
    socialMediaItemsByArea() {
      let socialMediaItemsAux = []
      let auxSocialMedia = null
      if (this.currentMunicipality && this.currentMunicipality.code &&
        Number.isInteger(Number(this.currentMunicipality.code))) {
        auxSocialMedia = this.socialMediaItems
          .filter(socialMedia => socialMedia.scope ===
            Number(this.currentMunicipality.code))
        if (auxSocialMedia && auxSocialMedia.length > 0)socialMediaItemsAux = auxSocialMedia[0]
      } else {
        auxSocialMedia = this.socialMediaItems
          .filter(socialMedia => socialMedia.scope === this.default)
        if (auxSocialMedia && auxSocialMedia.length > 0)socialMediaItemsAux = auxSocialMedia[0]
      }
      if (!auxSocialMedia) {
        socialMediaItemsAux = this.socialMediaItems
      }

      return socialMediaItemsAux
    }
  },

  methods: {
    login() {
      this.$emit('login')
    },

    logout() {
      this.$emit('logout')
    },

    closeMenu() {
      this.$emit('close-menu')
    },

    changeLanguage() {
      this.$emit('change-language')
    }
  }
})
