import Vue from 'vue'
import Bowser from 'bowser'

import template from './index.pug'

const mozillaImg = require('assets/images/browsers/mozilla.svg')
const chromeImg = require('assets/images/browsers/chrome.svg')
const edgeImg = require('assets/images/browsers/edge.svg')
const safariImg = require('assets/images/browsers/safari.svg')

// @vue/component
Vue.component('outdated-browsers', {
  template: template(),

  data() {
    return {
      outdatedCookies: undefined,
      open: false,
      browsers: [
        {
          img: mozillaImg,
          label: this.$t('outdatedBrowsers.browsers.mozilla')
        },
        {
          img: chromeImg,
          label: this.$t('outdatedBrowsers.browsers.chrome')
        },
        {
          img: edgeImg,
          label: this.$t('outdatedBrowsers.browsers.edge')
        },
        {
          img: safariImg,
          label: this.$t('outdatedBrowsers.browsers.safari')
        }
      ]
    }
  },

  created() {
    this.outdatedCookies = this.$cookies.get('outdatedBrowserMessage')
    this.analyzeBrowser()
  },

  methods: {
    analyzeBrowser() {
      const browser = Bowser.getParser(window.navigator.userAgent)

      const isValidBrowser = browser.satisfies({
        edge: '>=44',
        chrome: '>=67',
        firefox: '>=60',
        safari: '>=12',
        mobile: {
          safari: '>=12',
          android: '>=5'
        },
        tablet: {
          safari: '>=12',
          android: '>=5'
        }
      })

      this.open = !isValidBrowser
    },

    closeModal() {
      this.open = false
      this.$cookies.set('outdatedBrowserMessage', 'true', Infinity)
      this.outdatedCookies = 'true'
    }
  }
})
