import Vue from 'vue'
import {COMPLAINTS_URL} from 'js/lib/info.js'

import template from './index.pug'

const ComplaintsBook = require('assets/images/ilustrations/links/complaints-book.png')

// @vue/component
export default Vue.extend({
  template: template(),

  data() {
    return {
      complaintsUrl: COMPLAINTS_URL,
      complaintsBook: ComplaintsBook
    }
  }
})
