import Vue from 'vue'
import template from './index.pug'

Vue.component('language-modal', {
  template: template(),

  props: {},

  data() {
    return {
      languageValue: undefined
    }
  },

  computed: {
    availableLanguages() {
      return Vue.language.availableLanguages
    }
  },

  created() {
    this.languageValue = Vue.language.getLanguage()
  },

  methods: {
    close() {
      this.$emit('close')
    },

    getLanguageSrc(language) {
      return require(`assets/images/language/${language}.png`) // eslint-disable-line
    },

    setSelectedLanguage(language) {
      Vue.language.changeLanguage(language)
      this.close()
      this.$emit('language-changed')
    }
  }
})
