import Vue from 'vue'
import {mapGetters} from 'vuex'
import template from './index.pug'

// @vue/component
Vue.component('map-caption', {
  template: template(),

  computed: {
    ...mapGetters(['administrativeArea']),

    administrativeAreaConfig() {
      return this.administrativeArea.config
    },

    haveAnyCaption() {
      const {administrativeAreaConfig} = this

      if (!administrativeAreaConfig) { return false }

      const {sanitization_flag, accessibility_flag, occupancy_flag} = administrativeAreaConfig

      return sanitization_flag || accessibility_flag || occupancy_flag
    }
  }
})
