import Vue from 'vue'
import template from './index.pug'

// @vue/component
Vue.component('navigation-tabs', {
  template: template(),

  props: {
    tabItems: {
      type: Array,
      default: undefined
    }
  },

  methods: {
    change(index) {
      this.$emit('step', index)
    }
  }
})
