import Vue from 'vue'
import template from './index.pug'

// @vue/component
Vue.component('schedules-table', {
  template: template(),

  props: {
    schedules: {
      type: Array,
      required: true
    },
    showInitialState: {
      type: Boolean,
      default: false
    },
    fullRouteTime: {
      type: Boolean,
      default: true
    },
    customTime: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      query: false
    }
  },

  watch: {
    schedules() {
      this.query = true
    }
  },

  methods: {
    arrivalTime(schedule) {
      const duration = this.durationFormat(schedule)

      const momentObject = this.timeStringToMoment(schedule.journey_time).add(duration, 'minutes')

      return this.momentToTimeString(momentObject)
    },

    durationFormat(schedule) {
      return Math.round(schedule.duration / 60)
    },

    startTime(schedule) {
      return this.momentToTimeString(this.timeStringToMoment(schedule.journey_time))
    },

    timeStringToMoment(timeString) {
      return moment(timeString, 'hh:mm:ss')
    },

    momentToTimeString(momentObject) {
      return momentObject.format('HH:mm')
    }
  }
})
