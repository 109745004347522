/*
 * Librerías y componentes de terceros
 */

import 'js/vendor'

/*
 * Filtros de la aplicación
 */

import 'js/filters'

/*
 * Componentes de la aplicación
 */

import 'js/components'

/*
 * Mixins de la aplicación
 */

import 'js/mixins'

/*
 * Layout de la aplicación
 */

import DefaultLayout from 'js/layouts/default'

/*
 * Plugins de Vue
 */

// Vuex
import store from 'js/vue-plugins/vuex'

// Router de la aplicación
import router from 'js/vue-plugins/vue-router'

// Idioma de la aplicación
import i18n from 'js/vue-plugins/vue-i18n'

// Plugins de vue de la aplicación
// https://vuejs.org/v2/guide/plugins.html
// Se deben importar antes de instanciar Vue
import 'js/vue-plugins'

/*
 * Estilo de la aplicación
 */

// CSS de la aplicación
require('scss/main.scss')

/*
 * Configuración pre montaje
 */

require('js/config/before-mount')

/*
 * Montaje de Vue
 */

// Create and mount the root instance.
// Make sure to inject the router with the router option to make the
// whole app router-aware.
new Vue({
  router,
  store,
  i18n,
  components: {
    DefaultLayout
  }
}).$mount('#app')

/*
 * Configuración post montaje
 */

require('js/config/after-mount')
