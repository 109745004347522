import Vue from 'vue'
import {GENERAL_TRANSPORT_CONDITIONS_URL} from 'js/lib/info.js'

import template from './_help-links.pug'

// @vue/component
export default Vue.extend({
  template: template(),

  data() {
    return {
      generalTransportConditionsUrl: GENERAL_TRANSPORT_CONDITIONS_URL
    }
  },

  methods: {
    goToPrivacyPolicy() {
      this.$emit('privacy-policy')
    },

    openLanguageSelector() {
      this.$emit('change-language')
    },

    goToTransportRequest() {
      this.$emit('transport-request')
    },

    goToClaims() {
      this.$emit('claims')
    },

    showContactModal() {
      this.$emit('show-contact-modal')
    },

    goToAdvertising() {
      this.$emit('advertising')
    },

    goToInstitutionalInformation() {
      this.$emit('institutional-information')
    },

    goToPrivacyPolicyText() {
      this.$emit('privacy-policy-text')
    },

    goToCookiesPolicy() {
      this.$emit('cookies-policy')
    },

    goToRenting() {
      this.$emit('renting')
    },

    goToGeneralConditionsOfTransport() {
      this.$emit('general-conditions-of-transport')
    },

    goToBolsa() {
      this.$emit('bolsa-emprego')
    },

    goToDenuncia() {
      this.$emit('canal-denuncia')
    },

    goToPressNews() {
      this.$emit('press-news')
    },
  }
})
