export default ({
  state: {
    showLanguageModal: false
  },
  mutations: {
    SET_SHOW_LANGUAGE_MODAL(state, value) {
      state.showLanguageModal = value
    }
  },
  getters: {
    showLanguageModal: state => state.showLanguageModal
  },
  actions: {
    openLanguageModal({commit}) {
      commit('SET_SHOW_LANGUAGE_MODAL', true)
    },

    closeLanguageModal({commit}) {
      commit('SET_SHOW_LANGUAGE_MODAL', false)
    }
  }
})
